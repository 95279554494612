@import '../../styles/colors.scss';

.disclaimer-box {
  display: flex;
  background-color: rgba($background-warning, 0.5);
  border: 1px solid $border-warning;
  border-radius: 4px;
  padding: 1em;
  margin-bottom: 1em;

  &__icon {
    width: 32px;
    margin-right: 1em;

    svg {
      font-size: 22px;
      margin-left: 2px;
      opacity: 0.8;
    }
  }

  &.info {
    background-color: rgba($background-info, 0.25);
    border: 1px solid $border-info;
  }

  &.error {
    background-color: rgba($background-error, 0.25);
    border: 1px solid $border-error;
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    color: $text-color;
    margin: 0;
    margin-bottom: 0.5em;
  }

  .description {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    margin: 0;
  }
}