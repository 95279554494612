@import '../../styles/colors.scss';

.MuiDialog-root.enrollment-term-dialog {
  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 96px;
    padding: 1em;
    border: $border;
    border-radius: 4px;
    background-color: $white;
  }

  .checkbox-grid {
    text-align: right;
    
    .checkbox {
      background-color: initial;
    }
  }
}

.enrollment-terms {
  margin: 1.5em;

  .enrollment-terms-actions {
    width: 100%;
    text-align: right;
    margin-bottom: 1em;
  }
}

.enrollments-table {
  margin: 1.5em;
}