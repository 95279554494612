// app colors
$base: #fff;
$base-dark: #f8f8f8;
$main: #262626;
$text-color: #262626;
$accent: #c56fd5;
$accent-light: #d79ce2;

// utils
$border: 1px solid #cdcdcd;
$border-color: #cdcdcd;

// ultra
$ultra-border: 1px solid #cdcdcd;
$ultra-light-border: .0625rem solid #cdcdcd;
$ultra-text: #262626;
$ultra-lighttext: #666;
$ultra-bg: #f8f8f8;
$ultra-header-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 #cdcdcd;

// ucavila
$ucavila-blue: #003F62;
$ucavila-blue-soft: #86AFD9;
$ucavila-red: #AD0022;

// notifications
$background-success: rgba(186, 224, 185, 0.95);
$background-error: rgba(255, 201, 201, 0.95);
$background-warning: rgba(255, 238, 193, 0.95);
$background-info: rgba(153, 255, 255, 0.95);
$border-success: rgba(186, 224, 185, 1);
$border-error: rgba(255, 201, 201, 1);
$border-warning: rgba(255, 238, 193, 1);
$border-info: rgba(153, 255, 255, 1);

// base colors
$navy: #001F3F;
$blue: #0074D9;
$aqua: #7FDBFF;
$teal: #39CCCC;
$olive: #3D9970;
$green: #2ECC40;
$lime: #01FF70;
$yellow: #FFDC00;
$orange: #FF851B;
$red: #FF4136;
$fuchsia: #F012BE;
$purple: #B10DC9;
$maroon: #85144B;
$white: #FFFFFF;
$silver: #DDDDDD;
$gray: #AAAAAA;
$black: #111111;

// utils
$box-border: 1px solid #cdcdcd;
