@import '../../styles/colors.scss';

.MuiBackdrop-root.application-loading {
  color: $accent;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;

  .spinner-container {
    position: relative;

    .MuiCircularProgress-root.base-spinner {
      color: $accent-light;
    }

    .MuiCircularProgress-root.main-spinner {
      color: $accent;
      animation-duration: 750ms;
      position: absolute;
      left: 0;

      circle {
        stroke-linecap: round;
      }
    }

  }
}