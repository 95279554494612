@import '../../styles/colors.scss';

.badges {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;

  .badge {
    border-radius: 16px;
    padding: 2px 12px;
    min-width: 84px;
    font-size: 11px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid $main;
    background-color: $main;
    color: $base;

    &.clear {
      border: 1px solid $main;
      color: $main;
      background-color: transparent;
    }

    &.green {
      border: 1px solid $green;
      background-color: $green;
    }

    &.yellow {
      border: 1px solid $yellow;
      background-color: $yellow;
      color: $main;
    }

    &.red {
      border: 1px solid $red;
      background-color: $red;
    }

    &.blue {
      border: 1px solid $blue;
      background-color: $blue;
    }
    
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}