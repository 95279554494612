@import '../../../styles/colors.scss';

.MuiDialog-root.court-form-dialog {
  .divider {
    margin-top: 1em;
    padding-bottom: .5em;
    margin-bottom: .5em;

    &--actions {
      .MuiIconButton-root {
        padding: 5px;
      }
    }
  }

  .timespans-table {
    &:not(.empty) {
      .MuiTableCell-root {
        padding: .25em 1em;
      }
    }
  }

  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 148px;
    padding: 1em;
    margin: .5em;
    border: $border;
    border-radius: 4px;
    background-color: $white;
  }

  .add-timespan-button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
      padding: 10px 16px;
    }
  }
}

.MuiDialog-root.defense-request-dialog {
  .MuiTextField-root.input {
    .MuiFilledInput-root:not(.Mui-disabled) {
      background-color: white;
    }
  }

  .term-selector {
    padding: 0;
    border: none;
    margin-bottom: 0;
  }

  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 148px;
    padding: 1em;
    margin: .5em;
    border: $border;
    border-radius: 4px;
    background-color: $white;
  }
}

.tfg-court {
  &__header {
    width: 100%;
    text-align: right;
    margin-bottom: 1em;
  }

  .tfg-court-table-container {
    ul.court-members {
      padding: 0;
      margin: 0;
    }
  }
}

.student-requests {
  .tfg-defense-table {
    margin-top: 1.5em;
  }
}

.defense-requests {
  &__actions {
    width: 100%;
    text-align: right;
    margin-bottom: 1em;
  }

  ul.court-members {
    padding: 0;
    margin: 0;
  }
}