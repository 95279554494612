@import '../../styles/colors.scss';

.element-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-width: 13.75rem;
  padding: 1em;
  border: 1px solid rgba($border-color, .35);
  position: relative;
  // border-radius: 4px;

  &.active::after {
    content: '';
    position: absolute;
    bottom: 0em;
    left: 0;
    width: 100%;
    height: .175em;
    background-color: $accent;
    // border-radius: 4px 4px 0 0;
  }

  &.clickable:not(.active) {
    cursor: pointer;

    &:hover {
      box-shadow: .25rem .25rem 0 0 rgba($main, 0.08);
    }
  }

  &--image {
    max-height: 48px;
    max-width: 100%;
    padding-bottom: .5em;

    &.placeholder {
      width: 48px;
      height: 48px;
      padding-bottom: .25em;
    }
  }

  &--title {
    font-size: .875rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--favorite {
    position: absolute;
    right: 10px;
    top: 10px;

    .star-icon {
      &.active {
        font-size: 24px;
        color: $accent;
      }
    }
  }
}