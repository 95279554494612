@import '../../styles/colors.scss';

.checkbox {
  background-color: $white;
  margin-bottom: 1em;
  border-radius: 4px;

  label .MuiFormControlLabel-label {
    color: $text-color;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    font-size: .95em;
    font-weight: 600;
  }

  span.MuiCheckbox-root {
    padding: 4px 9px;
  }

  p {
    font-size: 0.825rem;
    margin: 0;
  }
}