@import '../../../styles/colors.scss';

.tfg-home {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2em;
  gap: 1em;

  .element-card {
    min-width: 40%;
    border-radius: 4px;
    border-color: rgba($border-color, .65);

    .MuiIcon-root {
      font-size: 2.5rem;
    }
  }
}