@import '../../styles/colors.scss';

.divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // justify-content: center;
  padding-bottom: 1em;
  border-bottom: $border;
  margin-bottom: 1em;

  &--title {
    font-weight: 600;
    margin: 0;
    font-size: 1.125rem;
  }
}