@import './colors.scss';

// Confirmation modal base styling
.confirmation-dialog .MuiDialogActions-root {
  padding: 0.75em 1.5em;
}

/* Notifications */
.notification {
  opacity: 0.95;

  &[role=alert] {
    border-radius: 4px;
    box-shadow: 0 6px 20px 0 rgba(0, 47, 73, 0.3);
  }

  #notistack-snackbar {
    font-size: 13px;
    font-weight: 600;
    font-family: 'Open sans', sans-serif;
    max-width: 386px;
  }

  &.success {
    background-color: $background-success;
    color: $text-color;
    border: 1px solid $border-success;
  }

  &.error {
    background-color: $background-error;
    color: $text-color;
    border: 1px solid $border-error;
  }

  &.warning {
    background-color: $background-warning;
    color: $text-color;
    border: 1px solid $border-warning;
  }

  &.info {
    background-color: $background-info;
    color: $text-color;
    border: 1px solid $border-info;
  }
}

.MuiTextField-root.input {
  input {
    background-color: white;
  }
}

.MuiAutocomplete-root {
  .MuiTextField-root.input {
    input {
      background-color: initial;
    }
  }

  .MuiFilledInput-root:not(.Mui-disabled) {
    background-color: $white;

    &:hover {
      background-color: $white;
    }
  }
}

// Overwrite default MUI dialog z-index
div.MuiDialog-root {
  z-index: 115 !important;
}

.MuiInputAdornment-root.loading-adornment {
  margin: 0 !important;

  .input-spinner {
    margin-right: 0.5em;
    margin-left: 0.25em;
    opacity: 0.5;
    color: #6d7278;
  }
}