@import '../../styles/colors.scss';

.instances {
  margin: 1em 2em;

  .spinner-container {
    width: 100%;
    min-height: 256px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .instances-table-container {
    .MuiToolbar-root h6 {
      color: rgba(0, 0, 0, 0.87);
    }

    .MuiTableCell-head,
    .MuiPaper-root {
      background-color: #f5f5f5;
    }

    .MuiTableRow-root:not(.MuiTableRow-footer) {
      background-color: white;
    }

    .instance-actions {
      display: flex;
      flex-wrap: nowrap;
      margin-left: -8px;

      .MuiButtonBase-root {
        padding: 9px;

        &:hover {
          color: $ucavila-blue;
        }
      }
    }

    .instances-table {
      .MuiTableRow-root {
        height: 76px;
      }

      &.clickable {
        .MuiTableRow-root:not(.MuiTableRow-footer):hover {
          cursor: pointer;
          background-color: rgba($aqua, 0.1);
        }
      }
    }
  }

  .upload-csv {
    padding: 1em;
    border: 1px solid #cdcdcd;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 1em;

    .disclaimer {
      padding: 1em 2em;
      border: 1px solid #cdcdcd;
      background-color: white;
      border-radius: 4px;
      margin-bottom: 1em;
    }

    &__actions {
      text-align: right;

      .MuiIconButton-root {
        margin-right: 1em;
      }
    }
  }

  .template-actions {
    // padding: 1em;
    // border: 1px solid #cdcdcd;
    // background-color: #f5f5f5;
    // border-radius: 4px;
    margin-bottom: 1em;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;

    .checkbox {
      background-color: initial;
      margin-bottom: 0;
    }
  }

  &.student {
    .instance-type {
      margin-bottom: 1em;
    }
  }

  .filters {
    padding: 1em;
    border: 1px solid #cdcdcd;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 1.5em;

    h3 {
      margin: 0;
      color: $main;
      font-size: 1.25rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      letter-spacing: 0.0075em;
    }

    .MuiSelect-filled {
      background-color: white;
    }

    .checkbox {
      background-color: white;
      padding: .5em 1em;
      border: $border;
      border-radius: 4px;
      margin-bottom: 0;

      label .MuiFormControlLabel-label {
        font-weight: 400;
      }
    }

    &--actions {
      width: 100%;
      text-align: right;
    }
  }

  &.on-behalf {
    .external-toggle {
      width: 100%;
      text-align: right;
      margin: 1em 25px;

      .MuiButton-root {
        margin-right: 3em;
      }

      .MuiIcon-root {
        margin: 0 .5em;
      }
    }

    .selected-user {
      padding: .5em 1.5em;
      border: $border;
      border-radius: 4px;
      background-color: $base-dark;

      .MuiIconButton-root {
        margin-right: .5em;
      }
    }

    .loading-table-container {
      height: 240px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.MuiDialog-root.instance-form-dialog {
  // z-index: 250 !important;

  .MuiDialogTitle-root {
    margin: 0.5em 1em;
  }

  .MuiDialogContent-root {
    padding: 1em 2em;
  }

  .MuiDialogActions-root {
    padding: 1em;
    margin: 0 1em;
  }

  .input-date {
    .MuiFormControl-root .MuiInputBase-root {
      background-color: $ucavila-blue-soft;
    }
  }
}

.instance-request-dialog, .instance-response-dialog, .instance-files-dialog {
  .actions {
    margin-right: 1em;
    margin-bottom: 0.5em;
  }

  .MuiFilledInput-multiline {
    background-color: $white;
  }
  
  .upload-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 0.25em 1em;
    margin-bottom: 1em;
    border: $border;
    border-radius: 4px;

    .student-file-actions, .instructor-file-actions {
      .MuiIconButton-root {
        margin-right: .5em;
      }
    }
  }
}

.instance-response-dialog {
  .student-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    border: $border;
    border-radius: 4px;
  }

  .disclaimer {
    margin: 0;
    margin-bottom: 1em;
    padding: 1em;
    border: $border;
    border-radius: 4px;
    background-color: $background-warning;
  }

  .request-outcome {
    padding: 1em;
    border: $border;
    border-radius: 4px;
    background-color: $white;
    color: rgba(0, 0, 0, 0.38);

    p {
      margin: 0;
      margin-bottom: 0.25em;
    }
  }
}