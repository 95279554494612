@import '../../styles/colors.scss';

.tfg {
  margin: 1.5em;

  .upload-csv {
    padding: 1em;
    border: $border;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 1em;

    .disclaimer {
      padding: 1em 2em;
      border: $border;
      background-color: white;
      border-radius: 4px;
      margin-bottom: 1em;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1em;
    }
  }

  .MuiPaper-root.stepper {
    background-color: initial;
  }

  .stepper {
    margin-top: 1em;
  }

  .stepper-body {
    padding: 1em;
    max-width: 80%;
    margin: auto;

    .selected-user {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      padding-right: 1em;
    }

    .MuiFormControl-root:not(:last-child) {
      margin-bottom: 1em;
    }

    .search-results {
      margin-top: 1em;
    }

    .MuiAutocomplete-root .MuiFilledInput-root:not(.Mui-disabled),
    .MuiTextField-root .MuiFilledInput-root:not(.Mui-disabled) {
      background-color: #eee;
    }

    .MuiTextField-root .MuiFilledInput-root:not(.Mui-disabled) input {
      background-color: initial;
    }

    .file-handler {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .current-file {
        margin-bottom: 1em;
        padding: 1em;
        border: $border;
        background-color: #f5f5f5;
        border-radius: 4px;
      }
    }
  }

  .stepper-actions {
    // padding: 0 2em;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .subject-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
  }

  .instance-selector {
    h3 {
      color: #262626;
      margin: 0;
      margin-left: 3px;
      margin-bottom: 0.5em;
    }

    margin-bottom: 1em;
    padding: 1em;
    border: $border;
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  .tfg-terms {
    .tfg-terms-actions {
      width: 100%;
      text-align: right;
      margin-bottom: 1em;
    }
  }

  .requests-filters {
    padding: 1em;
    border: 1px solid #cdcdcd;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 1.5em;

    h3 {
      margin: 0;
      color: $main;
      font-size: 1.25rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      letter-spacing: 0.0075em;
    }

    .MuiSelect-filled {
      background-color: white;
    }

    .checkbox {
      background-color: white;
      padding: .5em 1em;
      border: $border;
      border-radius: 4px;
      margin-bottom: 0;

      label .MuiFormControlLabel-label {
        font-weight: 400;
      }
    }

    .filters--actions {
      padding: 10px 0;
      width: 100%;
      text-align: right;
    }
  }

  .preference-disclaimer__container {
    // margin: 1em 5em;
    display: flex;
    justify-content: center;

    .preference-disclaimer {
      max-width: 80%;
    }
  }
}

.subject-form-dialog {
  .MuiAutocomplete-tag {
    height: 20px;
    font-size: 12px;

    .MuiChip-label {
      padding-top: 2px;
    }

    .MuiChip-deleteIcon {
      width: 16px;
      height: 16px;
    }
  }

  .multiple .MuiInputBase-root {
    padding-top: 1.75em;
  }
}

.table-progress-container {
  width: 100%;
  min-height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.tfg-requests-table .actions {
  display: flex;
  min-height: 36px;

  .MuiIconButton-root {
    padding: 6px;
  }
}

.tfg-config {
  h3 {
    color: #262626;
    margin: 0;
    margin-left: 3px;
    margin-bottom: 0.5em;
  }

  .title {
    font-weight: 600;
  }

  .term-selector {
    padding: 0;
    background-color: initial;
    border: none;
    margin-bottom: 0;
  }

  .actions {
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
  }

  .directors-list {
    padding: 1em;
    border: 1px solid #cdcdcd;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 1em;
    max-height: 428px;
    overflow-y: auto;
    padding-bottom: 2em;

    h3 {
      margin-left: 0;
    }

    span {
      display: block;
      margin-bottom: 0.25em;
    }

    .tfg-instructor {
      display: flex;
      justify-content: space-between;
    }

    .spinner-container {
      display: flex;
      min-height: 184px;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .update-instructor {
    padding: 1em;
    border: 1px solid #cdcdcd;
    background-color: #f5f5f5;
    border-radius: 4px;

    hr {
      margin-top: 1em;
      color: #cdcdcd;
      opacity: 0.65;
    }

    .instructor-allocation {
      .allocation__list {
        padding: 1em;
        border: 1px solid #cdcdcd;
        background-color: white;
        border-radius: 4px;
        margin-bottom: 1em;

        p {
          margin: 0;
          margin-bottom: .5em;
        }
      }
    }

    .selected-coord p {
      margin-left: 3px;
    }

    .current-tfgs {
      padding: 1em;
      border: 1px solid #cdcdcd;
      background-color: white;
      border-radius: 4px;
      margin-bottom: 1em;

      span {
        display: block;

        &:not(:last-child) {
          margin-bottom: 0.25em;
        }
      }
    }

    .update-button {
      width: 100%;
    }
  }
}

.MuiDialog-root.reject-request-dialog {
  p {
    margin-top: 0;
  }

  .MuiDialogActions-root {
    margin-right: 1em;
    margin-bottom: 0.5em;
  }
}

.instance-option {
  span.info {
    font-size: 14px;
    margin-bottom: 5px;
  }

  h5.name {
    margin: 0;
    font-size: 16px;
  }
}

.MuiDialog-root.update-instructors-dialog {
  .success {
    padding: 1em;
    border-left: 2px solid $green;
  }

  .error {
    padding: 1em;
    border-left: 2px solid $red;
    margin-top: 1em;
  }
}

.MuiDialog-root.tfg-term-dialog {
  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 96px;
    padding: 1em;
    border: $border;
    border-radius: 4px;
    background-color: $white;
  }
}

.term-selector {
  padding: 1em;
  background-color: #f5f5f5;
  border: $border;
  margin-bottom: 1em;

  .MuiFilledInput-root {
    background-color: $white;

    &.Mui-focused {
      background-color: $white;
      // outline: 1px solid $ucavila-blue;
    }
  }
}

.MuiDialog-root.instructor-allocation-dialog {
  .degree-limits {
    margin-top: 1em;

    .header {
      display: flex;
      justify-content: space-between;
    }
  }
}